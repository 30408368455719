import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import * as classes from '../../../custom-styles/Home.module.css'
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { device } from '../../../utils/breakpoints';
import HomeLogo from '../../../images/HomeLogo.svg';
import YAMLData from '../../../data/solutions/enterprise.yaml'

const HeaderContent = styled.div`
    padding: 40px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 18px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;


export default function HomePage() {

  return (
    <Container className={`${classes.py50}`}>
      <Row>
        <Col className={`${classes.backgroundimagescover}`}>
          <HomeLogo />
        </Col>
      </Row>
      <Row>
        <Col>
          <HeaderContent>
            <Typography variant="h3" gutterBottom><strong>{YAMLData.title}</strong></Typography>
            <Typography variant="h5" gutterBottom>{YAMLData.description}</Typography>
          </HeaderContent>
        </Col>
      </Row>
    </Container>
  )
}
